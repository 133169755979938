import { EventBarrier } from "@/components/common/event-barrier";
import { useFileUploaderWithPromise } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import {
  EmbeddedToolbar,
  EmbeddedToolbarButton,
} from "@/components/ui/embedded-toolbar";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useShareAnnotationOrAnalysis } from "@/hooks/use-share-annotation-or-analysis";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { useCurrentArea } from "@/modes/mode-data-context";
import { selectAnalysisIsDirty } from "@/store/point-cloud-analysis-tool-selector";
import {
  PointCloudAnalysis,
  removeAnalysis,
  setAnalysisDirtyFlag,
  setAnalysisVisibility,
} from "@/store/point-cloud-analysis-tool-slice";
import { selectAnnotationSection } from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import {
  activateTool,
  deactivateTool,
  setShowSpinner,
  ToolName,
} from "@/store/ui/ui-slice";
import { createAnalysisJsonFile } from "@/tools/analysis-saving-utils";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import {
  AddLabelIcon,
  ANNOTATION_ZINDEX_RANGE_MAP,
  Checkmark3Icon,
  DeleteIcon,
  fetchProjectIElements,
  neutral,
  NonVisibleIcon,
  PointCloudAnalysisColormapIcon,
  PointCloudAnalysisReferencePlaneIcon,
  selectChildDepthFirst,
  selectIElement,
  ShareLinkIcon,
  useToast,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import {
  isAnalysisSection,
  isAnnotationGroup,
  SupportedUnitsOfMeasure,
} from "@faro-lotv/ielement-types";
import {
  createMutationAddAnalysis,
  createMutationDeleteElement,
  createMutationExchangeFileUri,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { Box, Tooltip, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { Vector3 } from "three";
import { AppAwareHtml } from "../app-aware-html";
import { MEASURE_ANIMATION_LENGTH } from "../measurements/measure-constants";
import { ColormapOptionsPanel } from "./colormap-options-panel";
import { ColormapReferencePlanePanel } from "./colormap-reference-plane-panel";

type AnalysisActionBarProp = {
  /** 3D point to anchor the html component to. */
  anchorPoint: Vector3;

  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;

  /** Unit of measure to use for the labels */
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Callback called when the user wants to toggle the unit of measure */
  toggleUnitOfMeasure(): void;
};

enum AnalysisOptionPanel {
  ColorScale = "colorScale",
  ReferencePlane = "referencePlane",
}

/**
 * @returns Action toolbar for modify active analysis.
 */
export function AnalysisActionBar({
  anchorPoint,
  analysis,
  unitOfMeasure,
  toggleUnitOfMeasure,
}: AnalysisActionBarProp): JSX.Element | null {
  const parentRef = useViewOverlayRef();
  const appStore = useAppStore();
  const client = useCurrentProjectApiClient();
  const { handleErrorWithToast } = useErrorHandlers();

  const buttonSize = "36px";
  const actionBarPointOffset = "2px";

  const [activePanel, setActivePanel] = useState<AnalysisOptionPanel>();

  const dispatch = useAppDispatch();
  const deleteActiveAnalysis = useCallback(async () => {
    dispatch(setShowSpinner(true));
    const analysisElement = selectIElement(analysis.id)(appStore.getState());
    try {
      if (analysisElement) {
        await client.applyMutations([
          createMutationDeleteElement(analysisElement.id),
        ]);
      }
      setActivePanel(undefined);
      dispatch(removeAnalysis(analysis.id));
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to delete the point cloud analysis",
        error,
      });
    }
    dispatch(setShowSpinner(false));
  }, [analysis.id, appStore, client, dispatch, handleErrorWithToast]);

  const activeTool = useAppSelector(selectActiveTool);

  const hideAnalysis = useCallback(() => {
    dispatch(
      setAnalysisVisibility({
        analysisId: analysis.id,
        visible: false,
      }),
    );
    dispatch(deactivateTool());
  }, [analysis.id, dispatch]);

  const addLabels = useCallback(() => {
    if (activeTool === ToolName.analysisLabel) {
      dispatch(deactivateTool());
    } else {
      dispatch(activateTool(ToolName.analysisLabel));
    }
  }, [activeTool, dispatch]);

  const changeActivePanel = useCallback(
    (panel: AnalysisOptionPanel) => {
      setActivePanel(activePanel === panel ? undefined : panel);
    },
    [activePanel],
  );

  const analysisElement = useAppSelector(selectIElement(analysis.id));
  const shareAnalysis = useShareAnnotationOrAnalysis(analysis.id);
  return (
    <>
      <AppAwareHtml
        portal={parentRef}
        position={anchorPoint}
        zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
        eps={-1}
        style={{
          transform: `translate(-${actionBarPointOffset}, calc(-100% - ${actionBarPointOffset}))`,
        }}
      >
        <EmbeddedToolbar
          isActive
          sx={{
            transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
            padding: "0px",
            backgroundColor: neutral[999],
            borderRadius: "6px",
          }}
        >
          <Tooltip title="Change color scale" placement="top">
            <EmbeddedToolbarButton
              aria-label="change color scale"
              buttonSize={buttonSize}
              value="Change color scale"
              selected={activePanel === AnalysisOptionPanel.ColorScale}
              onClick={() => changeActivePanel(AnalysisOptionPanel.ColorScale)}
            >
              <PointCloudAnalysisColormapIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change reference plane" placement="top">
            <EmbeddedToolbarButton
              aria-label="change reference plane"
              buttonSize={buttonSize}
              value="Change reference plane"
              selected={activePanel === AnalysisOptionPanel.ReferencePlane}
              onClick={() =>
                changeActivePanel(AnalysisOptionPanel.ReferencePlane)
              }
            >
              <PointCloudAnalysisReferencePlaneIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Add labels" placement="top">
            <EmbeddedToolbarButton
              aria-label="add labels"
              buttonSize={buttonSize}
              value="Add labels"
              selected={activeTool === ToolName.analysisLabel}
              onClick={addLabels}
            >
              <AddLabelIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Change units" placement="top">
            <EmbeddedToolbarButton
              aria-label="change unit"
              buttonSize={buttonSize}
              value="Change Unit"
              onClick={toggleUnitOfMeasure}
            >
              <Typography>{unitOfMeasure === "metric" ? "m" : "ft"}</Typography>
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip
            title={
              analysisElement
                ? "Share a link to the analysis"
                : "Save analysis to generate link"
            }
            placement="top"
          >
            <EmbeddedToolbarButton
              aria-label="hide analysis"
              buttonSize={buttonSize}
              value="Deep link"
              disabled={!analysisElement}
              onClick={shareAnalysis}
            >
              <ShareLinkIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <Tooltip title="Hide analysis" placement="top">
            <EmbeddedToolbarButton
              aria-label="hide analysis"
              buttonSize={buttonSize}
              value="Hide analysis"
              onClick={hideAnalysis}
            >
              <NonVisibleIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
          <SaveAnalysisAction analysis={analysis} buttonSize={buttonSize} />
          <Tooltip title="Delete" placement="top">
            <EmbeddedToolbarButton
              aria-label="delete analysis"
              buttonSize={buttonSize}
              value="Delete"
              onClick={deleteActiveAnalysis}
            >
              <DeleteIcon />
            </EmbeddedToolbarButton>
          </Tooltip>
        </EmbeddedToolbar>
      </AppAwareHtml>
      {activePanel && (
        <AppAwareHtml
          portal={parentRef}
          position={anchorPoint}
          zIndexRange={ANNOTATION_ZINDEX_RANGE_MAP.toolbar}
          eps={-1}
          style={{
            transform: `translate(-${actionBarPointOffset}, +${actionBarPointOffset})`,
          }}
        >
          <EventBarrier>
            <Box
              component="div"
              padding={1}
              sx={{
                borderRadius: 1,
                backgroundColor: neutral[999],
              }}
            >
              {activePanel === AnalysisOptionPanel.ColorScale && (
                <ColormapOptionsPanel
                  analysis={analysis}
                  unitOfMeasure={unitOfMeasure}
                />
              )}
              {activePanel === AnalysisOptionPanel.ReferencePlane && (
                <ColormapReferencePlanePanel
                  analysis={analysis}
                  unitOfMeasure={unitOfMeasure}
                />
              )}
            </Box>
          </EventBarrier>
        </AppAwareHtml>
      )}
    </>
  );
}

type SaveAnalysisActionProp = {
  /** Active analysis object to be saved. */
  analysis: PointCloudAnalysis;

  /** save button size */
  buttonSize: string;
};

/**
 * @returns Save analysis action toolbar button.
 */
export function SaveAnalysisAction({
  analysis,
  buttonSize,
}: SaveAnalysisActionProp): JSX.Element {
  const appStore = useAppStore();
  const client = useCurrentProjectApiClient();
  const { openToast } = useToast();
  const { handleErrorWithToast } = useErrorHandlers();
  const { area } = useCurrentArea();
  const { coreApiClient } = useApiClientContext();
  const uploadFile = useFileUploaderWithPromise();
  const dispatch = useAppDispatch();

  const isAnalysisDirty = useAppSelector(selectAnalysisIsDirty(analysis.id));

  const saveAnalysis = useCallback(async () => {
    dispatch(setShowSpinner(true));

    const cloudElement = selectIElement(analysis.parentId)(appStore.getState());
    assert(
      cloudElement,
      "There must be point cloud associated to the point cloud analysis",
    );
    const annotationSection = selectAnnotationSection(
      cloudElement,
      area,
    )(appStore.getState());
    assert(annotationSection, "There must be parent annotation section");

    const worldMatrix = selectIElementWorldMatrix4(annotationSection.id)(
      appStore.getState(),
    );

    const file = createAnalysisJsonFile(analysis, worldMatrix);
    try {
      const { downloadUrl, md5 } = await uploadFile({
        file,
        coreApiClient,
        uploadElementType: UploadElementType.none,
        projectId: client.projectId,
        silent: true,
      });

      const analysisElement = selectIElement(analysis.id)(appStore.getState());
      if (analysisElement) {
        // IElement already exist, update file uri
        await client.applyMutations([
          createMutationExchangeFileUri(analysisElement.id, {
            md5Hash: md5,
            fileName: file.name,
            fileSize: file.size,
            uri: downloadUrl,
          }),
        ]);

        // Fetch the changed element and update the local copy of the project
        dispatch(
          fetchProjectIElements({
            fetcher: async () => {
              return await client.getAllIElements({
                ids: [analysisElement.id],
              });
            },
          }),
        );
      } else {
        // add new IElementAnalysis

        // Check if the parent annotation section already contains an annotation group (Nodes group)
        const nodesGroup = selectChildDepthFirst(
          annotationSection,
          isAnnotationGroup,
          1,
        )(appStore.getState());

        // check if the group (Nodes) already contains analysis section
        const analysisSection = nodesGroup
          ? selectChildDepthFirst(
              nodesGroup,
              isAnalysisSection,
              1,
            )(appStore.getState())
          : undefined;

        await client.applyMutations([
          createMutationAddAnalysis({
            rootId: annotationSection.rootId,
            sectionId: annotationSection.id,
            newElementId: analysis.id,
            groupId: nodesGroup?.id,
            analysisSectionId: analysisSection?.id,
            signedUri: downloadUrl,
            md5Hash: md5,
            fileName: file.name,
            fileSize: file.size,
            name: "analysis",
          }),
        ]);

        // Fetch the changed section sub-tree and update the local copy of the project
        dispatch(
          fetchProjectIElements({
            fetcher: async () => {
              return await client.getAllIElements({
                ancestorIds: [annotationSection.id],
              });
            },
          }),
        );
      }
    } catch (error) {
      handleErrorWithToast({
        title: "Failed to save point cloud analysis",
        error,
      });
      return;
    }

    openToast({
      title: "Point cloud analysis has been saved",
      variant: "success",
    });

    dispatch(
      setAnalysisDirtyFlag({
        analysisId: analysis.id,
        dirty: false,
      }),
    );

    dispatch(setShowSpinner(false));
  }, [
    analysis,
    appStore,
    area,
    client,
    coreApiClient,
    dispatch,
    handleErrorWithToast,
    openToast,
    uploadFile,
  ]);

  return (
    <Tooltip title="Save" placement="top">
      <EmbeddedToolbarButton
        aria-label="save analysis"
        buttonSize={buttonSize}
        value="Save"
        disabled={!isAnalysisDirty}
        onClick={(ev) => {
          ev.stopPropagation();
          saveAnalysis();
        }}
      >
        <Checkmark3Icon />
      </EmbeddedToolbarButton>
    </Tooltip>
  );
}
