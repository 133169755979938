import { Divider } from "@mui/material";
import { Stack } from "@mui/system";
import { ProcessInfoSource } from "../progress-overview-selectors";
import { ExportTaskCard } from "../tasks-cards/export-task-card";

type ExportOverviewTabProps = {
  /** The list of export tasks showed in the tab */
  exports: ProcessInfoSource[];
};

/** @returns The tab for the export tasks */
export function ExportOverviewTab({
  exports,
}: ExportOverviewTabProps): JSX.Element {
  return (
    <>
      <Stack component="div" mt={2}>
        {exports.map(({ id }, index) => (
          <Stack component="div" key={index}>
            <ExportTaskCard taskID={id} />
          </Stack>
        ))}
      </Stack>

      {!!exports.length && <Divider />}
    </>
  );
}
