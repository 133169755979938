import { ToAlignCard } from "../other-cards/to-align-card";
import { TaskCard } from "../progress-overview-card";
import { ProcessInfoSource } from "../progress-overview-selectors";

type ProgressOverviewTabProps = {
  /** The information about the tasks showed in this tab */
  processes: ProcessInfoSource[];
};

/** @returns The tab used for import and registration tasks */
export function ProgressOverviewTab({
  processes,
}: ProgressOverviewTabProps): JSX.Element {
  return (
    <>
      {processes.map((process) =>
        process.type === "ElementToAlignSection" ? (
          <ToAlignCard iElementID={process.id} key={process.id} />
        ) : (
          <TaskCard taskID={process.id} key={process.id} />
        ),
      )}
    </>
  );
}
