import { removeBackgroundTask } from "@/store/background-tasks/background-tasks-slice";
import { selectActiveAreaOrThrow } from "@/store/selections-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  BackgroundTask,
  getErrorCodeAndTaskName,
} from "@/utils/background-tasks";
import {
  FaroText,
  FontWeights,
  NoTranslate,
  neutral,
  red,
} from "@faro-lotv/flat-ui";
import { selectIElement } from "@faro-lotv/project-source";
import {
  ProgressApiSupportedTaskTypes,
  taskErrorToUserMessage,
} from "@faro-lotv/service-wires";
import { useCallback, useMemo } from "react";
import { CardAction, GenericCardLayout } from "../layouts/generic-card-layout";

type ErrorCardProps<Task extends BackgroundTask = BackgroundTask> = {
  /** Id of the task to show an error card for */
  task: Task;
};

/**
 * @returns Dismissible card showing an error and the cause, if available
 */
export function ErrorCard({ task }: ErrorCardProps): JSX.Element {
  const dispatch = useAppDispatch();
  const element = useAppSelector(selectIElement(task.iElementId ?? ""));
  const activeArea = useAppSelector(selectActiveAreaOrThrow(element));

  const dismissErrorTask = useCallback((): void => {
    dispatch(removeBackgroundTask(task.id));
  }, [dispatch, task.id]);

  const { taskName, errorCode } = getErrorCodeAndTaskName(task, element?.name);

  const elementName = useMemo(() => {
    if (element?.name) return <NoTranslate>{element.name}</NoTranslate>;
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "SCENE conversion";
    }
  }, [task, element]);

  const errorMessage = useMemo(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return (
        <>
          <FaroText variant="labelM" color={red[500]}>
            Conversion failed.
          </FaroText>
          <FaroText variant="bodyS" color={neutral[600]}>
            Save again the project in SCENE and press the Sync button to start
            the processing.
          </FaroText>
        </>
      );
    }
    return (
      <FaroText
        variant="bodyS"
        color={red[500]}
        fontWeight={FontWeights.SemiBold}
      >
        {taskErrorToUserMessage(taskName, errorCode)}
      </FaroText>
    );
  }, [errorCode, task.type, taskName]);

  const menu = useMemo<CardAction[]>(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) return [];
    return [
      {
        name: "Clear",
        callback: dismissErrorTask,
      },
    ];
  }, [dismissErrorTask, task.type]);

  return (
    <GenericCardLayout
      name={elementName}
      // Avoid putting the floor name if the task does not reference an element
      subText={
        element ? <NoTranslate>{activeArea.name}</NoTranslate> : undefined
      }
      menu={menu}
      startTime={task.createdAt}
      sx={{ gap: 1.25 }}
    >
      {errorMessage}
    </GenericCardLayout>
  );
}
