/**
 * Download a file
 *
 * @param url The url to the file to download
 * @param name Optional name of the downloaded file
 */
export function downloadFile(url: string, name?: string): void {
  const anchor = document.createElement("a");
  anchor.href = url;
  if (name) {
    anchor.download = name;
  }
  anchor.click();
  anchor.remove();
}
